import React from "react"
import styles from "./brand_security.module.scss"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

const BrandSecurity = () => {
  const images = useStaticQuery(graphql`
      query {
          partner1: file(relativePath: { eq: "partner-logo1.png" }) {
              childImageSharp {
                  fixed(width: 117, height: 31) {
                      ...GatsbyImageSharpFixed
                  }
              }
          },
          partner2: file(relativePath: { eq: "partner-logo2.png" }) {
              childImageSharp {
                  fixed(width: 124, height: 31) {
                      ...GatsbyImageSharpFixed
                  }
              }
          },
          partner3: file(relativePath: { eq: "partner-logo3.png" }) {
              childImageSharp {
                  fixed(width: 144, height: 35) {
                      ...GatsbyImageSharpFixed
                  }
              }
          },
          partner4: file(relativePath: { eq: "partner-logo4.png" }) {
              childImageSharp {
                  fixed(width: 126, height: 38) {
                      ...GatsbyImageSharpFixed
                  }
              }
          },
          partner5: file(relativePath: { eq: "partner-logo5.png" }) {
              childImageSharp {
                  fixed(width: 119, height: 23) {
                      ...GatsbyImageSharpFixed
                  }
              }
          },
          partner6: file(relativePath: { eq: "partner-logo6.png" }) {
              childImageSharp {
                  fixed(width: 152, height: 30) {
                      ...GatsbyImageSharpFixed
                  }
              }
          },
          partner7: file(relativePath: { eq: "partner-logo7.png" }) {
              childImageSharp {
                  fixed(width: 154, height: 36) {
                      ...GatsbyImageSharpFixed
                  }
              }
          },
          partner8: file(relativePath: { eq: "partner-logo8.png" }) {
              childImageSharp {
                  fixed(width: 143, height: 32) {
                      ...GatsbyImageSharpFixed
                  }
              }
          },
          partner9: file(relativePath: { eq: "partner-logo9.png" }) {
              childImageSharp {
                  fixed(width: 119, height: 37) {
                      ...GatsbyImageSharpFixed
                  }
              }
          },
          partner10: file(relativePath: { eq: "partner-logo10.png" }) {
              childImageSharp {
                  fixed(width: 136, height: 27) {
                      ...GatsbyImageSharpFixed
                  }
              }
          },
      }
  `)
  return (
    <div className={styles.wrapper}>
      <div className={styles.brand_security}>
        <div className={styles.item + " " + styles.up}><Img fixed={images.partner1.childImageSharp.fixed} /></div>
        <div className={styles.item + " " + styles.up}><Img fixed={images.partner2.childImageSharp.fixed} /></div>
        <div className={styles.item + " " + styles.up}><Img fixed={images.partner3.childImageSharp.fixed} /></div>
        <div className={styles.item + " " + styles.up}><Img fixed={images.partner4.childImageSharp.fixed} /></div>
        <div className={styles.item + " " + styles.up}><Img fixed={images.partner5.childImageSharp.fixed} /></div>
        <div className={styles.item + " " + styles.down}><Img fixed={images.partner6.childImageSharp.fixed} /></div>
        <div className={styles.item + " " + styles.down}><Img fixed={images.partner7.childImageSharp.fixed} /></div>
        <div className={styles.item + " " + styles.down}><Img fixed={images.partner8.childImageSharp.fixed} /></div>
        <div className={styles.item + " " + styles.down}><Img fixed={images.partner9.childImageSharp.fixed} /></div>
        <div className={styles.item + " " + styles.down}><Img fixed={images.partner10.childImageSharp.fixed} /></div>
      </div>
    </div>
  )
}

export default BrandSecurity

