import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styles from "./post.module.scss"
import ProductSelector from "../components/productSelector"
import BrandSecurity from "../components/brand_security"

export default ({ data }) => {
  const post = data.allWpPost.edges[0].node
  const categories = data.allWpPost.edges[0].node.categories.nodes || []
  return (
    <Layout>
      <div
        style={{
          backgroundImage: `url(${post.images.image.localFile.childImageSharp.fluid.src})`,
          backgroundSize: `cover`
        }}
      >
        <div
          className={styles.header_title}
          style={{ background: `rgba(0, 0, 0, 0.6)` }}
        >
          <div className="container">
            <h1>{post.title}</h1>
            <h2>Publish on: {post.date}</h2>
            <h3 style={{ textTransform: "capitalize" }}>
              {categories
                .map(cat => cat.name)
                .reduce((prev, curr) => [prev, ", ", curr])}
            </h3>
          </div>
        </div>
      </div>
      <div className="container">
        <Seo
          title={post.title}
          description={post.seo.metaDesc}
          keywords={post.keywords.keywords}
          author={post.author.node.name}
        ></Seo>

        <div
          dangerouslySetInnerHTML={{ __html: post.content }}
          className={styles.postContent}
        ></div>
        <p>By: {post.author.node.name}</p>
        <hr />
      </div>
      <ProductSelector article={encodeURI(post.title)}/>
      <BrandSecurity />
    </Layout>
  )
}

export const query = graphql`
    query($id: String!) {
        allWpPost(filter: { id: { eq: $id } }) {
            edges {
                node {
                    categories {
                        nodes {
                            name
                        }
                    }
                    images {
                        image {
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 1024) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                    id
                    slug
                    content
                    title
                    keywords {
                        keywords
                        fieldGroupName
                    }
                    seo {
                        metaDesc
                    }
                    author {
                        node {
                            name
                        }
                    }
                    date(formatString: "MM-DD-YYYY")
                }
            }
        }
    }
`
